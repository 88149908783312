import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { ApyLabel } from '../label';
import { Col, Row, TextSubheading, TextBody } from '../lib';
import { ListItem } from '../list';
import { createStyles } from '../theme';
export const _MarketListItem = memo(({ addon, apy, coinIcon, displayCode, footer, id, onPress, subtitle, trending = false, }) => {
    let subtitleEl = null;
    if (typeof subtitle === 'string') {
        subtitleEl = _jsx(TextBody, { children: subtitle });
    }
    else if (subtitle) {
        subtitleEl = subtitle;
    }
    return (_jsx(ListItem, { id: id, onPress: onPress, children: _jsxs(Row.LRT, { children: [coinIcon && (_jsx(Col, { mt: "xxs", mr: "s", children: coinIcon })), _jsxs(Col, { style: $.content, children: [_jsxs(Row.LR, { children: [_jsxs(Col, { children: [_jsxs(Row.L, { children: [_jsx(TextSubheading, { children: displayCode }), !!apy && apy > 0 && (_jsx(Col, { ml: "xxs", children: _jsx(ApyLabel, { value: apy }) })), !!trending && _jsx(TextSubheading, { ml: "xxs", children: "\uD83D\uDD25" })] }), !!subtitleEl && _jsx(Col, { style: $.subtitle, children: subtitleEl })] }), !!addon && addon] }), !!footer && footer] })] }) }));
});
const $ = createStyles({
    content: {
        flex: 1,
    },
    subtitle: {
        marginTop: 2,
    },
});
