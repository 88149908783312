import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
// TODO(Hadrien): Should we depend on common in a UC ?
import { useCopy } from 'common';
import { Col, TextBody } from '../lib';
import { BadgeIcon } from '../badge-icon';
export const SearchEmpty = memo(({ title, subtitle }) => {
    const { widgets: { assetSearchNoResult }, } = useCopy();
    return (_jsxs(Col.C, { p: "xl", children: [_jsx(BadgeIcon, { name: "manage-search", size: "md" }), _jsx(TextBody, { mt: "xs", em: true, medium: true, children: title || assetSearchNoResult.title }), _jsx(TextBody, { mt: "xs", style: $.text, children: subtitle || assetSearchNoResult.subtitle })] }));
});
const $ = StyleSheet.create({
    text: {
        textAlign: 'center',
    },
});
