import { createTheme } from 'react-native-themesheet';
const primaryColor = '#181428';
const neutralColor = '#676767';
export const colors = {
    // Background
    background: 'white',
    backgroundAlt: '#F8F8F8',
    // Surface
    surface: 'white',
    onSurface: neutralColor,
    onSurfaceDim: '#959595',
    onSurfaceInv: '#FFFFFF',
    onSurfaceEm: primaryColor,
    onSurfaceNegative: '#b33117',
    onSurfacePositive: '#229437',
    onTextInput: primaryColor,
    onTextInputDim: neutralColor,
    onTextInputEm: primaryColor,
    // Surface (neutral)
    neutralSurface: '#ECECEC',
    onNeutralSurface: primaryColor,
    // Surface (info)
    infoSurface: '#EEF0FC',
    onInfoSurface: '#3F4B9C',
    // Surface (alt)
    surfaceActive: '#fafafa',
    surfaceAlt: '#fafafa',
    // Surface (Accent)
    surfaceAccent: '#58ffb2',
    surfaceAccentActive: '#defff0',
    // Border
    outline: '#ececec',
    infoSurfaceBorder: '#C5CBF4',
    accent: '#58ffb2',
    primary: '#181428',
    error: '#b33117',
    success: '#2a8a3a',
    textInfoDark: '#5262DF',
    // Utils
    transparent: 'transparent',
};
const sizes = {
    rad1: 4,
    rad2: 2,
    xxs: 4,
    xs: 8,
    s: 12,
    m: 16,
    l: 24,
    xl: 32,
};
// https://github.com/eightyfive/react-native-themesheet
// In a nutshell:
//   - createStyles({ color: 'colorName' }): "colorName" is type-checked,
//   - createStyles({ pr: 'm' }): "pr" is a shortcut (type-checked) for "paddingRight" and "m" ia size name (type-checked)
//   - createBox: see lib/box.tsx
export const { createBox, createStyles } = createTheme(colors, sizes);
