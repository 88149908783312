import { jsx as _jsx } from "react/jsx-runtime";
import { API } from 'api';
import { useMemo } from 'react';
import { SortableHeader } from '../list';
import { TableCell, TableRow } from '../table';
import { createStyles } from '../theme';
import { MARKETS_HEADERS } from './constants';
const ACTIONS_HEADER = { label: '', width: '5%', isSortable: false };
export const BOND_HEADERS = [
    { ...MARKETS_HEADERS.NAME, width: '30%' },
    { ...MARKETS_HEADERS.YIELD, width: '10%' },
    { ...MARKETS_HEADERS.COUPON, width: '10%' },
    { ...MARKETS_HEADERS.PRICE, width: '11%', isSortable: false },
    { ...MARKETS_HEADERS.MATURITY, width: '12%' },
    { ...MARKETS_HEADERS.FREQUENCY, width: '10%' },
    { ...MARKETS_HEADERS.ISSUANCE_AMOUNT, width: '12%' },
    ACTIONS_HEADER,
];
export const CRYPTO_HEADERS = [
    { ...MARKETS_HEADERS.NAME, width: '55%' },
    { ...MARKETS_HEADERS.PRICE, width: '10%' },
    { ...MARKETS_HEADERS.PERCENTAGE_CHANGE, width: '10%' },
    { ...MARKETS_HEADERS.VOLUME, width: '10%' },
    { ...MARKETS_HEADERS.MARKET_CAP, width: '10%' },
    ACTIONS_HEADER,
];
export const FIAT_HEADERS = [
    { ...MARKETS_HEADERS.NAME, width: '75%', isSortable: false },
    { ...MARKETS_HEADERS.PRICE, width: '10%', isSortable: false },
    { ...MARKETS_HEADERS.PERCENTAGE_CHANGE, width: '10%', isSortable: false },
    ACTIONS_HEADER,
];
export const FUND_HEADERS = [
    { ...MARKETS_HEADERS.NAME, width: '38%' },
    { ...MARKETS_HEADERS.CURRENCY, width: '8%', isSortable: false },
    { ...MARKETS_HEADERS.ROR_MTD, width: '15%', isSortable: false },
    { ...MARKETS_HEADERS.ROR_YTD, width: '15%', isSortable: false },
    { ...MARKETS_HEADERS.TOTAL_RETURN, width: '14%', isSortable: false },
    { ...MARKETS_HEADERS.MMD, width: '10%', isSortable: false },
];
export function MarketTableHeader({ assetType, ...sortable }) {
    // Vars
    const headers = useMemo(() => {
        if (assetType === API.MarketAssetType.Fiat) {
            return FIAT_HEADERS;
        }
        if (assetType === API.MarketAssetType.Fund) {
            return FUND_HEADERS;
        }
        if (assetType === API.MarketAssetType.Bond) {
            return BOND_HEADERS;
        }
        if (assetType === API.MarketAssetType.Crypto) {
            return CRYPTO_HEADERS;
        }
        return [];
    }, [assetType]);
    // Render
    return (_jsx(TableRow, { style: $.header, children: headers.map(({ isSortable = true, width, ...header }, idx) => {
            return (_jsx(TableCell, { width: width, align: idx > 0 ? 'right' : 'left', children: isSortable && !!header.id ? (_jsx(SortableHeader, { ...header, ...sortable, arrowSide: idx !== 0 ? 'left' : 'right' })) : (_jsx(SortableHeader, { ...header })) }, header.label));
        }) }));
}
const $ = createStyles({
    header: {
        backgroundColor: 'surface',
        borderColor: 'outline',
    },
});
