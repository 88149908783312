import { memo, PropsWithChildren, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import startCase from 'lodash/startCase';
import {
  ActionId,
  AppContainer,
  BuildEnv,
  CurrencyIcon,
  EnrichedAssetHolding,
  EnrichedAssetHoldings,
  getRuntimeEnv,
  useAppDialog,
  useEnvironmentConfig,
  useMarkets,
  UseMarketType,
} from 'common';
import { API } from 'api';
import {
  MarketTableHeader,
  MarketTableItemBond,
  MarketTableItemCrypto,
  MarketTableItemFiat,
  MarketTableItemFund,
  Pagination,
} from '@xbto/universal-components';
import { AppStore, DataStore } from '~/store';
import { AppTabs } from '~/components/app-tabs/app-tabs';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import {
  handleNavigation,
  OptionsParams,
  QueryParams,
} from '~/components/shared/url-params/url-params';
import { SearchEmpty } from '@xbto/universal-components';
import { AppLoader } from '~/components/app-loader';
import { MarketsColumn } from '~/config';
import { APP_ROUTES } from '~/routes';
import { WorkflowPortfolioRowButtons } from '~/components/workflows/row-buttons';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';
import { AccountSelector } from '~/components/workflows/account-selector';
import { GenericError } from '~/components/generic-error';
import { SearchInput } from '@xbto/universal-components';

enum QUERY_PARAMS {
  COLUMN = 'column',
  DIRECTION = 'direction',
  ASSET_TYPE = 'assetType',
  ASSET_CATEGORY = 'assetCategory',
}

/**
 * Table components
 */
type OnWorkflowSelected = (
  type: DASHBOARD_SELECTED_DIALOG_TYPE,
  asset: EnrichedAssetHoldings
) => void;

interface AssetWorkflowActionProps {
  assetCode: string;
  onWorkflowSelected: OnWorkflowSelected;
  placement: 'top' | 'bottom';
}

const AssetWorkflowAction = ({
  assetCode,
  onWorkflowSelected,
  placement = 'bottom',
}: AssetWorkflowActionProps) => {
  const allowedPortfolioActions = DataStore.useStoreState(
    s => s.portfolio.allowedActions
  );
  const canBuy = allowedPortfolioActions.includes(ActionId.Buy);
  const canSell = allowedPortfolioActions.includes(ActionId.Sell);
  const canSend = allowedPortfolioActions.includes(ActionId.Send);
  const canReceive = allowedPortfolioActions.includes(ActionId.Receive);
  const canAddCash = allowedPortfolioActions.includes(ActionId.AddCash);
  const canTransfer = allowedPortfolioActions.includes(ActionId.Transfer);
  const canWithdrawCash = allowedPortfolioActions.includes(
    ActionId.WithdrawCash
  );
  const canView = !(
    canBuy ||
    canSell ||
    canSend ||
    canReceive ||
    canAddCash ||
    canTransfer ||
    canWithdrawCash
  );

  return (
    <WorkflowPortfolioRowButtons
      assetCode={assetCode}
      capabilities={{
        canView,
        canBuy,
        canSell,
        canSend,
        canReceive,
        canAddCash,
        canTransfer,
        canWithdrawCash,
      }}
      onWorkflowSelected={onWorkflowSelected}
      placement={placement}
    />
  );
};

type TableProps = PropsWithChildren & {
  empty: boolean;
  loading?: boolean;
};

function Table({ empty, loading = false, ...rest }: TableProps) {
  return (
    <div
      {...rest}
      className={cx('relative flex flex-col border-1', {
        'border-b-0': !empty && !loading,
        'min-h-200': loading,
      })}
    />
  );
}

const TableRows = memo(
  ({
    isLoading,
    data,
    hasNoData,
    activeAssetType,
  }: Pick<
    TableDetailsProps,
    'data' | 'activeAssetType' | 'hasNoData' | 'isLoading'
  >) => {
    const navigate = useNavigate();
    const getAccountDetail = DataStore.useStoreActions(
      _ => _.portfolio.getAccountDetail
    );
    const setDashboardSelectedAsset = AppStore.useStoreActions(
      _ => _.setDashboardSelectedAsset
    );
    const setSelectedCurrencyCode = AppStore.useStoreActions(
      _ => _.setSelectedCurrencyCode
    );
    const setDashboardSelectedDialogType = AppStore.useStoreActions(
      _ => _.setDashboardSelectedDialogType
    );
    const { showDialog, hideDialog } = useAppDialog();

    const handlePressAsset = useCallback((currencyCode: string) => {
      navigate(`${APP_ROUTES.AUTH_ASSET}/${currencyCode}`);
    }, []);

    const beginWorkflow = useCallback(
      async (
        dialogType: DASHBOARD_SELECTED_DIALOG_TYPE,
        asset: EnrichedAssetHolding | null = null,
        accountId: string | null = null
      ) => {
        if (!accountId) {
          return;
        }
        // This is need to update the account info before opening the modal, otherwise Oops happens
        const { isSuccessful } = await getAccountDetail({
          accountId,
          isBackgroundXHR: false,
        });
        if (!isSuccessful) {
          return;
        }
        const isTradeFlow =
          dialogType === 'workflow-buy' || dialogType === 'workflow-sell';

        if (isTradeFlow) {
          setSelectedCurrencyCode(asset?.currency.code ?? null);
        } else {
          if (asset) {
            setDashboardSelectedAsset(asset);
          }
        }
        setDashboardSelectedDialogType(dialogType);
      },
      [
        setDashboardSelectedDialogType,
        setDashboardSelectedAsset,
        getAccountDetail,
      ]
    );
    const onWorkflowSelected = useCallback(
      (
        dialogType: DASHBOARD_SELECTED_DIALOG_TYPE,
        assetHoldings: EnrichedAssetHoldings
      ) => {
        if (!dialogType) {
          navigate(APP_ROUTES.AUTH_ASSET + `/${assetHoldings?.currency?.code}`);
          return;
        }

        const accounts = assetHoldings.accounts;
        if (accounts.length === 1) {
          beginWorkflow(
            dialogType,
            accounts[0],
            accounts[0].account?.accountId
          );
          return;
        }

        showDialog(
          <AccountSelector
            workflowType={dialogType}
            onClose={hideDialog}
            onNext={async account => {
              hideDialog();
              const asset = accounts.find(
                acc => acc.account?.accountId === account.account?.accountId
              );
              beginWorkflow(dialogType, asset, account.account?.accountId);
            }}
          />
        );
      },
      [showDialog, hideDialog, setDashboardSelectedDialogType, beginWorkflow]
    );

    if (isLoading) {
      return (
        <AppLoader spinnerTop="104px" isFixed={false} bgColor="transparent" />
      );
    }
    if (hasNoData) {
      return (
        <SearchEmpty
          title="No assets available"
          subtitle="No assets found under this search team, please try another term."
        />
      );
    }

    return (
      <>
        {data?.rows?.map((asset, index, arr) => {
          const isGreaterThanHalfItems = index > arr.length / 2;
          const itemProps = {
            apy: asset.apy,
            coinIcon: (
              <CurrencyIcon
                currencyCode={asset.code}
                size={24}
                showLabel={false}
              />
            ),
            currencyCode: asset.code,
            displayCode: asset.displayCode,
            name: asset.name,
            trending: asset.isTrending,
            onPress: handlePressAsset,
          };

          const actions = (
            <AssetWorkflowAction
              assetCode={asset.code}
              onWorkflowSelected={onWorkflowSelected}
              placement={isGreaterThanHalfItems ? 'top' : 'bottom'}
            />
          );

          if (
            activeAssetType === API.MarketAssetType.Fiat &&
            asset.data?.fiatMarketDetail
          ) {
            return (
              <MarketTableItemFiat
                key={asset.code}
                {...itemProps}
                actions={actions}
                data={asset.data.fiatMarketDetail}
              />
            );
          }

          if (
            activeAssetType === API.MarketAssetType.Bond &&
            asset.data?.bondMarketDetail
          ) {
            return (
              <MarketTableItemBond
                key={asset.code}
                {...itemProps}
                actions={actions}
                data={asset.data.bondMarketDetail}
              />
            );
          }

          if (
            activeAssetType === API.MarketAssetType.Fund &&
            asset.data?.fundMarketDetail
          ) {
            return (
              <MarketTableItemFund
                key={asset.code}
                {...itemProps}
                coinIcon={null}
                name={asset.valueAsOf}
                displayCode={asset.name}
                data={asset.data.fundMarketDetail}
              />
            );
          }

          if (
            activeAssetType === API.MarketAssetType.Crypto &&
            asset.data?.cryptoMarketDetail
          ) {
            return (
              <MarketTableItemCrypto
                key={asset.code}
                {...itemProps}
                actions={actions}
                data={asset.data.cryptoMarketDetail}
              />
            );
          }

          return null;
        })}
      </>
    );
  }
);

type TableDetailsProps = Omit<
  UseMarketType,
  | 'columns'
  | 'onPageChanged'
  | 'onSortBy'
  | 'assetTypes'
  | 'onAssetTypeChanged'
  | 'error'
> & {
  applyParams: (
    value: QueryParams<QUERY_PARAMS>,
    options?: OptionsParams<QUERY_PARAMS> | undefined
  ) => void;
  header?: JSX.Element;
  onPressPage: (page: number) => void;
};

const TableDetails = ({
  isLoading,
  activeAssetType,
  isActiveAssetOfTypeFund,
  categoriesByAssetType,
  isActiveAssetCategory,
  onSearchChanged,
  onPressPage,
  data,
  hasNoData,
  header,
  showPagination,
  showCategoriesAndSearch,
  onFiltersChanged,
  search,
}: TableDetailsProps) => {
  return (
    <div className="overflow-x-auto">
      <div className="min-w-880">
        {showCategoriesAndSearch && (
          <DashboardHeader withBorder title="">
            <div className="px-3">
              <div className="flex whitespace-nowrap overflow-auto outline scrollbar-hide py-4 gap-2">
                {categoriesByAssetType[activeAssetType]?.map(
                  ({ id, label, filters }) => (
                    <button
                      key={id}
                      className={cx(
                        {
                          'bg-grey-brighter text-secondary':
                            !isActiveAssetCategory?.(filters),
                          'bg-primary text-white':
                            isActiveAssetCategory?.(filters),
                        },
                        'h-8 my-auto px-4 font-semibold rounded button-xs text-xs  snap-center outline-none focus:outline-none'
                      )}
                      onClick={() => {
                        onFiltersChanged({
                          ...filters,
                          search: '',
                          categories:
                            id === 'all' ? [] : [id as API.MarketAssetCategory],
                        });
                      }}
                    >
                      {startCase(label)}
                    </button>
                  )
                )}
                <span className="ml-auto">
                  <SearchInput
                    onChange={onSearchChanged}
                    collapsed={false}
                    maxLength={500}
                    value={search}
                  />
                </span>
              </div>
            </div>
          </DashboardHeader>
        )}
        <Table empty={hasNoData} loading={isLoading}>
          {header}
          <TableRows
            activeAssetType={activeAssetType}
            isLoading={isLoading}
            data={data}
            hasNoData={hasNoData}
          />
        </Table>
        {isActiveAssetOfTypeFund && !hasNoData && !isLoading && (
          <div className="bg-grey-brighter pt-3 text-grey-darker text-xs">
            ¹Performance metrics based on actual realized returns.
          </div>
        )}
        {showPagination && (
          <Pagination
            currentPage={data?.pagination.page}
            totalPages={isLoading ? 0 : data?.pagination?.totalPages}
            onPressPage={onPressPage}
          />
        )}
      </div>
    </div>
  );
};

export const ListOfAssets = () => {
  const { REACT_APP_BUILD_ENV } = useEnvironmentConfig();
  const { isLowerRegionEnv } = getRuntimeEnv(REACT_APP_BUILD_ENV as BuildEnv);

  /**
   * Hooks
   */
  const {
    error,
    assetTypes,
    onAssetTypeChanged,
    data,
    onPageChanged: handlePressPage,
    onSortBy,
    ...rest
  } = useMarkets(DataStore);
  const {
    applyParams,
    queryParams: { assetType, assetCategory, direction, column },
  } = handleNavigation<QUERY_PARAMS>({
    [QUERY_PARAMS.ASSET_CATEGORY]: 'all',
    [QUERY_PARAMS.COLUMN]: MarketsColumn.Asset,
    [QUERY_PARAMS.DIRECTION]: API.SortDirection.Ascending,
  });
  const aT = assetTypes?.find(id => id.displayName === assetType);

  useEffectOnce(() => {
    const _assetType =
      (aT?.type as API.MarketAssetType) ||
      rest.activeAssetType ||
      API.MarketAssetType.Crypto;
    const _categories = rest.categoriesByAssetType[_assetType] ?? [];
    const _category =
      _categories.find(({ id }) => id === (assetCategory || 'all')) ?? null;
    const _filters = _category?.filters ?? rest.filters;

    _filters.types = [_assetType];
    _filters.sortColumn =
      API.MarketListAssetsSortType[column as string] || _filters.sortColumn;
    _filters.sortDirection =
      API.SortDirection[direction as string] || _filters.sortDirection;

    rest.onFiltersChanged(_filters);
  });

  // This must not trigger on inital render, only
  useUpdateEffect(() => {
    if (!aT) {
      return;
    }
    onAssetTypeChanged(aT.type);
  }, [aT]);

  useEffect(() => {
    applyParams(
      {
        [QUERY_PARAMS.ASSET_CATEGORY]: rest.filters?.categories?.length
          ? rest.filters?.categories
          : undefined,
        [QUERY_PARAMS.COLUMN]: rest.filters?.sortColumn,
        [QUERY_PARAMS.DIRECTION]: rest.filters?.sortDirection,
      },
      { append: true, replace: true }
    );
  }, [
    rest.filters.sortColumn,
    rest.filters.sortDirection,
    rest.filters.categories,
  ]);

  useEffectOnce(() => {
    return () => {
      onAssetTypeChanged(API.MarketAssetType.Crypto);
    };
  });

  /**
   * DOM
   */
  return (
    <AppContainer
      fullHeight
      containerWidth="lg"
      containerCls="flex flex-col"
      cls="h-full px-10 pb-10"
    >
      <AppTabs
        tabKey={QUERY_PARAMS.ASSET_TYPE}
        applyParams
        marginCls={error ? '' : 'mt-6 bg-white'}
        headerContainerCls="mt-10 w-min"
        spacingCls="gap-6"
        headerItemCls="border-primary"
        fontCls="font-bold capitalize text-primary"
        tabs={assetTypes?.map(item => (
          <div key={item.type} className="tab-header">
            {item.displayName}
          </div>
        ))}
        panels={
          error
            ? []
            : assetTypes?.map(item => (
                <TableDetails
                  data={data}
                  key={item.type}
                  {...rest}
                  applyParams={applyParams}
                  onPressPage={handlePressPage}
                  header={
                    <MarketTableHeader
                      sortId={rest.filters.sortColumn}
                      sortDir={rest.filters.sortDirection}
                      assetType={rest.activeAssetType}
                      onSort={onSortBy}
                    />
                  }
                />
              ))
        }
      />
      {error && (
        <GenericError
          error={isLowerRegionEnv ? new Error(error) : undefined}
          containerCls="flex-1"
        />
      )}
    </AppContainer>
  );
};
