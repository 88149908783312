import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { TextCaption } from '../lib';
export function SortableHeaderTitle({ active = false, id, label, onPress, }) {
    // Handlers
    const handlePress = useCallback(() => {
        if (id) {
            onPress?.(id);
        }
    }, [id, onPress]);
    // Render
    return (_jsx(Pressable, { disabled: !onPress, onPress: handlePress, children: _jsx(TextCaption, { em: true, color: active ? 'onSurfaceEm' : 'onSurfaceDim', children: label }) }));
}
