import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { colors } from '../theme';
import { Col, Icon, Row, TextBody, Underline } from '../lib';
import { Platform, Pressable, StyleSheet, useWindowDimensions, } from 'react-native';
export const AccountClosedBanner = memo(({ onContactSupport }) => {
    const { width } = useWindowDimensions();
    const isPhone = width < 768;
    const Container = !isPhone ? Row : Col;
    const contactSupport = (_jsx(Col, { style: [$.contactSupport, !isPhone && { marginTop: 0 }], children: _jsx(Pressable, { onPress: onContactSupport, children: _jsx(Underline, { children: _jsx(TextBody, { em: true, children: "Contact support" }) }) }) }));
    return (_jsxs(Container, { style: [$.container, !isPhone && $.containerRow], children: [_jsxs(Row, { style: [$.content, !isPhone && { alignItems: 'center' }], children: [_jsx(Icon, { name: "info", color: "textInfoDark", size: "md" }), _jsxs(Col, { style: { width: '100%', flexShrink: 1 }, children: [_jsx(TextBody, { em: true, medium: true, style: { fontSize: 14 }, children: "Account closed" }), _jsx(TextBody, { style: $.description, children: "This account is closed, if you have any questions please contact support" }), isPhone && contactSupport] })] }), !isPhone && contactSupport] }));
});
// TODO(Hadrien): Investigate with Benoit why createStyles blows up the webapp
// const $ = createStyles({
const $ = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: colors.infoSurfaceBorder,
        backgroundColor: colors.infoSurface,
        marginHorizontal: Platform.select({ web: 0, default: 16 }),
        marginBottom: Platform.select({ web: 24, default: 16 }),
        paddingHorizontal: Platform.select({ web: 24, default: 12 }),
        paddingVertical: 16,
        borderRadius: 4,
    },
    containerRow: {
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: 4,
    },
    content: {
        alignItems: 'flex-start',
        columnGap: 12,
    },
    description: { flex: 1, flexWrap: 'wrap' },
    contactSupport: {
        alignItems: 'flex-start',
        marginTop: 12,
    },
});
