import { jsx as _jsx } from "react/jsx-runtime";
import { colors, createStyles } from '../theme';
import { TextCaption } from './text';
export function Tag({ color, backgroundColor, label, em }) {
    const colorName = color || 'onNeutralSurface';
    const backgroundName = backgroundColor || 'neutralSurface';
    return (_jsx(TextCaption, { small: true, style: [
            $.tag,
            { color: colors[colorName], backgroundColor: colors[backgroundName] },
            em && $.em,
        ], numberOfLines: 1, children: label }));
}
const $ = createStyles({
    tag: {
        borderRadius: 2,
        marginLeft: 4,
        padding: 4,
    },
    em: {
        fontWeight: '500',
    },
});
