import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { TableCell } from '../table';
import { Col, Row, TextBody, TextSubheading } from '../lib';
import { ApyLabel } from '../label';
export const TableCellAsset = memo(({ apy, coinIcon, displayCode, name, trending, ...rest }) => {
    return (_jsx(TableCell, { ...rest, children: _jsxs(Row.L, { children: [coinIcon && _jsx(Col, { mr: "m", children: coinIcon }), _jsxs(Col, { style: $.row, children: [_jsxs(Row.L, { children: [_jsx(TextSubheading, { children: displayCode }), apy > 0 && (_jsx(Col, { ml: "xxs", children: _jsx(ApyLabel, { value: apy }) })), !!trending && _jsx(TextSubheading, { ml: "xxs", children: "\uD83D\uDD25" })] }), _jsx(TextBody, { small: true, children: name })] })] }) }));
});
const $ = StyleSheet.create({
    row: {
        rowGap: 2,
    },
});
